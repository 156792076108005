<template>
  <span class="text-md !leading-[19px] font-bold">
    {{ t(label) }}
  </span>
  <span v-if="!hideSuffix && !required">
    {{ ` (${t('formFields.optional')})` }}
  </span>
</template>
<script setup lang="ts">
const { t } = useTrans();
defineProps({
  label: {
    type: String,
    required: true,
  },
  required: {
    type: Boolean,
    default: true,
  },
  hideSuffix: {
    type: Boolean,
    default: false,
  },
});
</script>
